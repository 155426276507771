import React from 'react';
import './AnnouncementBar.css'; // Import your custom CSS file

const AnnouncementBar = () => {
  return (
    <div className="announcement-bar">
      <marquee>Eid Bumper Offers 50% Unlimited Games & Activities Just In Rs 900.</marquee>
    </div>
  );
};

export default AnnouncementBar;
